@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1,h2,h3,h4,h5,h6 {
      @apply font-inter ;
  }
  h2 {
      @apply text-2xl tracking-wider;
  }
  a {
      @apply font-inter; 
  }
  li {
    @apply font-inter;
  }
}